<template>
    <div class="header">
        <div class="content">
            <router-link to="/index">
                <img src="https://www.shugaogufen.com/images/logo.png" alt />
            </router-link>
            <div class="navigator">
                <router-link to="/index">首页</router-link>
                <router-link to="/product">产品服务</router-link>
                <!-- <router-link to="/case">客服案例</router-link> -->
                <router-link to="/news">新闻资讯</router-link>
                <router-link to="/about">关于我们</router-link>
            </div>
        </div>

        <div class="login" @click="vibileLogin=true">登录</div>

        <div class="mask" v-if="vibileLogin">
            <div class="login-box">
                <div class="title">登录</div>
                <div class="item">
                    <span>手机号：</span>
                    <p>
                        <input type="tel" v-model="phone" maxlength="11" placeholder="请输入手机号" />
                    </p>
                </div>
                <div class="item">
                    <span>验证码：</span>
                    <p>
                        <input type="tel" v-model="message" maxlength="6" placeholder="请输入验证码" />
                    </p>
                    <button @click="send_message()">{{mesText}}</button>
                </div>
                <div class="submit">
                    <span @click="submit()">提交</span>
                    <span @click="vibileLogin=false">关闭</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            vibileLogin: false,
            mesText: "验证码",
            timer: null,
            total: 60,
            phone:"",
            message:""
        }
    },
    methods: {
        send_message(){
            if(!this.phone){
                alert("请输入手机号");
                return;
            }
        },
        submit(){
            if(!this.phone){
                alert("请输入手机号");
                return;
            }
            if(!this.message){
                alert("请输入验证码");
                return;
            }
            this.vibileLogin = false;
        }
    },
};
</script>

<style lang="scss" scoped>
.header {
    background: #27272a;
    width: 100%;
    height: 106px;
    position: relative;
    .content {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        height: 106px;
        a,
        img {
            width: 275*0.8px;
            height: 71*0.8px;
        }
        .navigator {
            display: flex;
            align-items: center;
            height: 100%;
            margin-left: 110px;
            a {
                font-size: 15px;
                color: #aaa;
                width: 150px;
                height: 106px;
                display: flex;
                align-items: center;
                justify-content: center;
                &.router-link-exact-active {
                    color: #fff;
                    font-size: 20px;
                    position: relative;
                    &:after {
                        content: "";
                        width: 95px;
                        height: 4px;
                        background: #fff;
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
        }
    }
    .login {
        color: #fff;
        font-size: 16px;
        position: absolute;
        right: 50px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
}

.mask {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
    .login-box {
        width: 400px;
        overflow: hidden;
        padding: 20px 30px;
        background: #fff;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 5px;
        .title {
            font-size: 20px;
            text-align: center;
            padding: 0 0 30px;
        }
        .item {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            span {
                font-size: 14px;
                color: #333;
                width: 50px;
                white-space: nowrap;
            }
            p {
                display: block;
                height: 32px;
                border: 1px solid #e5e5e5;
                flex: 1;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                margin-left: 10px;
                padding-left: 10px;
                input {
                    width: 100%;
                    display: block;
                    font-size: 14px;
                    color: #333;
                }
            }
            button {
                width: 80px;
                height: 32px;
                background: #0080ff;
                font-size: 12px;
                color: #fff;
                text-align: center;
                line-height: 32px;
                cursor: pointer;
                &:disabled {
                    opacity: 0.7;
                }
            }
        }
        .submit {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px auto;
            margin-top: 30px;
            span {
                width: 80px;
                height: 32px;
                background: #0080ff;
                font-size: 14px;
                color: #fff;
                text-align: center;
                line-height: 32px;
                cursor: pointer;
                &:last-child {
                    margin-left: 20px;
                    background: #ccc;
                }
            }
        }
    }
}
</style>